import Vue from "vue";
import Vuex from "vuex";
// 加载storage模块：获取token，存储token
import { getItem, setItem } from "@/utils/storage";

Vue.use(Vuex);
// 用户登陆后的token值
const TOKEN_KEY = "X-Token";

export default new Vuex.Store({
  // 处理用户 Token
  state: {
    user: getItem(TOKEN_KEY),
    LOADING: false, // vuex全局控制loading显示与隐藏
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
      // 把数据备份到本地存储，防止vuex刷新丢失
      setItem(TOKEN_KEY, state.user);
    },
    showLoading(state) {
      state.LOADING = true;
    },
    hideLoading(state) {
      state.LOADING = false;
    },
  },
  actions: {},
  modules: {},
});
