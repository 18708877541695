// 路由-相关模块
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/", // 默认首次访问的页面
    component: () => import("../views/Login"),
  },
  {
    path: "/login", // url的路径
    name: "login", // router-link路由跳转时使用
    component: () => import("../views/Login"), // 组件页面路由懒加载
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register"),
  },
  {
    path: "/changepwd",
    name: "changepwd",
    component: () => import("../views/ChangePwd"),
  },
  {
    path: "/bindingTools",
    name: "bindingTools",
    component: () => import("../views/BindingTools"),
  },
  {
    path: "/mychongzhi",
    name: "mychongzhi",
    component: () => import("../views/MyChongzhi"),
  },
  {
    path: "/chongzhijilu",
    name: "chongzhijilu",
    component: () => import("../views/ChongZhijilu"),
  },
  {
    path: "/shiyongjilu",
    name: "shiyongjilu",
    component: () => import("../views/ShiYongjilu"),
  },
  {
    path: "/biaojuchongzhi",
    name: "biaojuchongzhi",
    component: () => import("../views/BiaojuChongzhi"),
  },
  {
    path: "/jiaoyimingxi",
    name: "jiaoyimingxi",
    component: () => import("../views/JiaoyiMingxi"),
  },
  {
    path: "/paysuccess",
    name: "paysuccess",
    component: () => import("../views/PaySuccess"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
