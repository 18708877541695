<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <!-- 路由出口 -->
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/components/loading'
export default {
  computed: {
    ...mapState(['LOADING'])
  },
  components: { Loading },
  name: "App",
};
</script>

