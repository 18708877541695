<template>
  <div class="loading">
    <van-loading
      type="spinner"
      size="50px"
      class="load"
    />
  </div>
</template>

<script>
export default {
  name: 'LOADING',
  data () {
    return {}
  },
}
</script>

<style lang="less" scoped>
.loading {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  .load {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    opacity: 0.4;
    color: #ffffff;
    border-radius: 20px;
    width: 130px;
    height: 130px;
  }
}
</style>