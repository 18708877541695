import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; // 管理用户token
import "vant/lib/index.css";
import "@/styles/common.less";
import "amfe-flexible"; // 手机页面适配 REM
import "@vant/touch-emulator"; // 桌面端将mouse事件转换成touch事件
import "./utils/time";
import axios from "axios";
// import Vconsole from "vconsole"; // 手机端调试返回日志
// let vConsole = new Vconsole();
// export default vConsole;

import {
  NavBar,
  Form,
  Field,
  Button,
  List,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Icon,
  Col,
  Row,
  Toast,
  Empty,
  Loading,
} from "vant"; // 按需引入,优化加载速度

Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(List);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Empty);
Toast.setDefaultOptions({ duration: 700 });

// 全局挂载
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
